var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","flat":"","color":"#e5f1f2"}},[_c('v-container',[_c('v-row',{attrs:{"justify-sm":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-toolbar-items',[_c('v-menu',{staticClass:"rounded-lg",attrs:{"offset-y":"","nudge-left":25,"nudge-bottom":5},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"ApoTalent Logo","contain":"","src":require("@/assets/apotalent/Isotipo white.png"),"transition":"scale-transition","width":"35"}}),_c('v-img',{staticClass:"shrink mt-1",attrs:{"alt":"ApoTalent Name","contain":"","min-width":"100","src":require("@/assets/apotalent/ApoTalent.svg"),"transition":"scale-transition","width":"70"}})],1)]}}])},[_c('v-list',_vm._l((_vm.legalOptions),function(option,index){return _c('v-list-item',{key:index,staticClass:"legal-options",attrs:{"to":{ name: option.router }},on:{"click":function () {}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(option.icon))]),_c('v-list-item-title',{staticClass:"secondary--text text-caption"},[_c('u',[_vm._v(_vm._s(_vm.$t(option.title)))])])],1)}),1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"rounded-lg"},[_c('v-list-item',{staticClass:"justify-end",on:{"click":function($event){_vm.menu = !_vm.menu}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1),_vm._l((_vm.mainOptions),function(option,index){return _c('v-row',{key:index,attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":!!option.router ? { name: option.router } : ''},on:{"click":function($event){option.key == 'language'
                      ? _vm.toggleLanguage()
                      : _vm.goToNewTab(option.router)}}},[(option.key == 'language')?_c('i18n',{attrs:{"menu":_vm.langaugeMenu},on:{"toggleLanguage":_vm.toggleLanguage}}):_vm._e(),_c('v-list-item-title',{staticClass:"font-weight-light secondary--text",staticStyle:{"font-size":"15px"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(option.icon))]),_vm._v(" "+_vm._s(_vm.$tc(option.title, 2))+" ")],1)],1)],1)})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }